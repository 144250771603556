
import Vue from "vue";

export default Vue.extend({
  name: "RangeInput",
  props: {
    threshold: {
      type: Number,
      default: 1
    },
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onChangePrice(isIncrease: boolean) {
      if (this.disabled) {
        return;
      }
      const lastVal = parseInt(this.value?.toString()) || 0;

      if (lastVal <= this.threshold - 1 && !isIncrease) {
        const amount = 0;
        this.$emit("input", amount.toString());
        return;
      }
      const newAmount = isIncrease
        ? lastVal + this.threshold
        : lastVal - this.threshold;

      this.$emit("input", newAmount.toString());
    },
    onlyNumber(event: any) {
      const ch = String.fromCharCode(event.which);
      if (!/[0-9]/.test(ch)) {
        event.preventDefault();
      }
    }
  }
});
