<template>
  <b-modal
    id="image-upload"
    ref="image-upload-modal"
    centered
    hide-footer
    hide-header
    size="sm"
  >
    <input
      id="image"
      style="display: none"
      type="file"
      accept=".jpg,.jpeg,.png,image/*"
      @change="onFileChange"
    />
    <h3 class="text-center font-weight-semi-bold text-primary mb-9">
      {{ $t("imageModal.imageUpload") }}
    </h3>
    <b-row no-gutters class="mx-8">
      <b-col
        class="bg-white px-5 px-xl-6 text-center cursor-pointer border-2 border-right"
        @click="showImageUpload"
      >
        <b-avatar class="d-block bg-light-blue mx-auto mb-4" size="4.3rem">
          <inline-svg
            :src="require(`@/assets/icons/camera.svg`)"
            class=""
            height="35.2"
            width="35.2"
          ></inline-svg>
        </b-avatar>
        <span class="d-block font-weight-medium font-size-14 text-color-3 mx-9">
          {{ $t("imageModal.browseLocally") }}
        </span>
      </b-col>
      <b-col
        v-b-modal.photo-gallery
        class="bg-white px-5 px-xl-6 text-center cursor-pointer"
      >
        <b-avatar class="d-block bg-light-blue mx-auto mb-4" size="4.3rem">
          <inline-svg
            :src="require(`@/assets/icons/picture-icon.svg`)"
            class=""
            height="35.2"
            width="35.2"
          ></inline-svg>
        </b-avatar>
        <span class="d-block font-weight-medium font-size-14 text-color-3 mx-9">
          {{ $t("imageModal.browseGallery") }}
        </span>
      </b-col>
    </b-row>
    <span
      class="d-block mx-8 mt-7 mb-6 font-size-12 font-weight-medium text-center"
    >
      {{
        $t(
          "By uploading your own image or using one of our gallery's cover image, you agree to our"
        )
      }}
      <a target="_blank">
        <span class="text-primary text-underline" role="link">{{
          $t("imageModal.termsConditions")
        }}</span>
      </a>
    </span>
    <b-row align-h="center" class="mt-3" no-gutters>
      <b-button
        class="text-color-2 mx-2 mx-sm-0 main-btn"
        variant="outline-dark"
        @click="removeImageAndCloseModal"
      >
        {{ $t("imageModal.later") }}
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "ImageUploadModal",
  components: {},
  props: {
    image: String
  },
  data() {
    return {
      src: this.image,
      btnLoading: false
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.$emit("setImage", URL.createObjectURL(file));
      this.$bvModal.hide("image-upload");
      this.$bvModal.show("clipper");
    },
    showImageUpload() {
      // @ts-ignore
      document.getElementById("image").click();
    },
    removeImageAndCloseModal() {
    this.$emit("setImage", null); // Remove the current image
    this.$refs['image-upload-modal'].hide(); // Close the modal
  }
  }
};
</script>
