
import Vue from "vue";

export default Vue.extend({
  name: "MentorcastLicenceModal",
  props: {},
  data() {
    return {};
  }
});
