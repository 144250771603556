
import Vue from "vue";
import ClipperModal from "@/components/ClipperModal.vue";
import MentorcastLicenceModal from "@/components/Modals/MentorcastLicenceModal.vue";
import ImageUploadModal from "@/components/Modals/ImageUploadModal.vue";
import MentorcastPhotoGalleryModal from "@/components/Modals/MentorcastPhotoGalleryModal.vue";
import { mapGetters } from "vuex";
import RangeInput from "@/components/Partials/RangeInput.vue";
import moment from "moment-timezone";

export default Vue.extend({
  name: "MentorcastForm",
  components: {
    ClipperModal,
    MentorcastLicenceModal,
    RangeInput,
    ImageUploadModal,
    MentorcastPhotoGalleryModal
  },
  props: {
    isPublished: Boolean,
    isCreated: Boolean,
    create: Boolean,
    platformCurrency: String,
    mentorcastData: Object,
    errors: Object,
    mentorcastImgUrl: String,
    languages: Array,
    platforms: Array,
    defaultPlatform: Number,
    audiences: Array,
    isLicence: Boolean,
    settings: Object,
    maxSeats: String,
    skipLicense: String,
    skipOverview: Boolean
  },
  data() {
    return {
      url: "",
      data: this.mentorcastData as any,
      submitBtnLoading: false,
      timeOptions: [
        {
          code: "am",
          label: (this as any).$t("mentorcastHero.timeOptionAMLabel")
        },
        {
          code: "pm",
          label: (this as any).$t("mentorcastHero.timeOptionPMLabel")
        }
      ],
      newCategory: "",
      maxTitleChars: 100,
      maxHostURLChars: 350,
      maxDescChars: 450,
      categoryError: "",
      dateTime: "",
      showPassword: false,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      certificateOptions: [
        {
          id: 1,
          name: (this as any).$t("mentorcastHero.certificateOptionsYes")
        },
        {
          id: 0,
          name: (this as any).$t("mentorcastHero.certificateOptionsNo")
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    }),
    isZeroPrice(): any {
      return this.data.seat_price <= 0;
    },
    getPriceThreshold(): any {
      return parseInt(this.settings?.basic?.priceThreshold) || 0;
    },
    getDurationThreshold(): any {
      return parseInt(this.settings?.basic?.durationThreshold) || 0;
    },
    isMentoriceZoom(): any {
      const index = this.platforms.findIndex(
        (e: any) => e.id === parseInt(this.data.mentoring_platform_id)
      );
      return (
        index > -1 &&
        //@ts-ignore
        this.platforms[index].name ==
          (this as any).$t("mentorcastHero.platformHostedByMentorice")
      );
    },
    getAvailableSeats(): any {
      return this.isZeroPrice
        ? this.buildSeatRange(this.settings?.nonPaidMentorcast?.basic)
        : this.buildSeatRange(this.settings?.paidMentorcast?.basic);
    }
  },
  watch: {
    newCategory() {
      this.validateCategory();
    },
    "data.seat_price": function(val) {
      if (parseInt(val) <= 0) {
        this.data.max_seats = "";

        const keys = {
          duration: "nonPaidMentorcast.defaults.duration",
          is_certificate: "nonPaidMentorcast.defaults.certificate_id",
          mentorcast_audience_id: "nonPaidMentorcast.defaults.audience_id",
          mentoring_platform_id: "nonPaidMentorcast.defaults.platform_id",
          language_id: "nonPaidMentorcast.defaults.language_id"
        } as any;

        for (const key of Object.keys(keys)) {
          this.data[key] = this.resolveDynamic(keys[key], this.settings);
        }
      }
    }
  },
  created(): void {
    this.countdown();
    this.setData();
  },
  methods: {
    buildSeatRange(data: any) {
      const seats = [];
      if (data !== undefined) {
        for (let i = data.startSeatRange; i <= data.endSeatRange; i = i + 48) {
          seats.push(i);
        }
      }
      if (this.maxSeats !== "") {
        const index = seats.indexOf(parseInt(this.maxSeats));
        if (index > -1) {
          seats.splice(0, index);
        }
      }
      return seats;
    },
    resolveDynamic(path: any, obj: any) {
      return path.split(".").reduce(function(prev: any, curr: any) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    selectDate(date: string) {
      this.data.start_date = date
        .split("-")
        .reverse()
        .join("-");
    },
    setData() {
      if (this.isPublished || this.isCreated) {
        this.data = { ...this.mentorcastData };
        this.url = this.mentorcastImgUrl;
      }
    },
    showLicense() {
      this.$bvModal.show("video-licence");
    },
    modalShowOrHide() {
      this.$store
        .dispatch("mentorcast/ACCEPT_ZERO_MENTORCAST_LICENCE", {
          title: this.data.title
        })
        .then(() => {
          this.$bvModal.hide("video-licence");
          this.$emit("setLicense", true);
          this.$emit("update-mentorcast", this.data, this.url);
        });
    },
    onNext() {
      if (this.skipLicense == "false") {
        this.$emit("update-mentorcast", this.data, this.url);
      } else {
        this.modalShowOrHide();
      }
    },
    showImageUpload() {
      this.$bvModal.show("image-upload");
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    validateCategory() {
      this.$emit("clearCategoryError");
      this.categoryError = "";

      if (this.data.categories.includes(this.newCategory.trim())) {
        this.categoryError = (this as any).$t(
          "mentorcastHero.categorySameError"
        );
      } else if (/\s/g.test(this.newCategory)) {
        this.categoryError = (this as any).$t(
          "mentorcastHero.categorySpaceError"
        );
      }
    },
    addCategory() {
      if (
        this.newCategory.trim().length === 0 ||
        this.data.categories.length >= 8 ||
        this.categoryError
      ) {
        return;
      }

      this.data.categories.push(this.newCategory);
      this.newCategory = "";
    },
    deleteCategory(index: number) {
      this.data.categories.splice(index, 1);
    },
    onDiscard() {
      if (!this.isPublished) {
        this.$router.push(`/profile`);
      } else {
        this.$router.push(`/mentorcasts/${this.$route.params.id}`);
      }
    },
    isDurationValid() {
      if (this.data.duration >= 540) {
        (this as any).$alertify.error(
          (this as any).$t("mentorcastHero.maxDurationError")
        );
        this.data.duration = 0;
        return false;
      }
      return true;
    },
    checkPlatformIsSelectable(option: any) {
      return !(
        option.name == "Hosted by Mentorice" &&
        parseInt(this.data.seat_price) <= 0
      );
    },
    countdown() {
      const zone = this.profile?.timezone_format || "Asia/Karachi";
      this.dateTime = moment()
        .tz(zone)
        .format("LT")
        .toString();
      setTimeout(() => this.countdown(), 1000);
    },
    setImageUrl(file: string) {
      this.url = file;
    },
    setGalleryImage(url: string) {
      this.url = url;
      this.$bvModal.hide("image-upload");
      this.$bvModal.hide("photo-gallery");
      this.$bvModal.show("clipper");
    }
  }
});
